
jQuery(function ($) { // この中であればWordpressでも「$」が使用可能になる

  //ドロワーメニュー
  $(".js-menu").click(function () {//ボタンがクリックされたら
    if($(this).text() === 'close') {
      $('.menu').text('menu');
    } else {
      $('.menu').text('close');
    }
    $(".js-drawer-menu").toggleClass('active');
    $(".sp-nav__bg").toggleClass('active');
    $(".sp-nav__mask").toggleClass('active');
  });

  //inview
  $(".inview").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("is-show");
    }
  });

  // //スクロールイベント
  // $(window).scroll(function () {
  //   $(".header__logo-link").css("opacity", "0");
  //   let scroll = $(this).scrollTop();
  //   if(scroll == 0){
  //     $(".header__logo-link").css("opacity", "1");
  //   }
  // });



  // memberセクション　スライダー1
  const swiper1 = new Swiper(".swiper1", {
    loop: true, // ループ有効
    slidesPerView: "auto", // 一度に表示する枚数
    speed: 3000, // ループの時間
    allowTouchMove: false, // スワイプ無効
    autoplay: {
      delay: 0, // 途切れなくループ
    },
  });

  // memberセクション　スライダー2
  const swiper2 = new Swiper(".swiper2", {
    loop: true, // ループ有効
    slidesPerView: "auto", // 一度に表示する枚数
    speed: 3000, // ループの時間
    allowTouchMove: false, // スワイプ無効
    autoplay: {
      delay: 0, // 途切れなくループ
      reverseDirection: true, // 逆方向有効化
    },
  });

  // memberページ　スライダー01
  const swiperMember01 = new Swiper(".swiperMember01", {
    loop: true, // ループ有効
    slidesPerView: "auto", // 一度に表示する枚数
    speed: 3000, // ループの時間
    allowTouchMove: false, // スワイプ無効
    autoplay: {
      delay: 0, // 途切れなくループ
    },
  });

  // serviceページ contentスワイパーchanmima


  const swiper4 = new Swiper ('.swiper4', {
    slidesPerView:1,//画像を何枚表示するか
    speed: 300,
    allowTouchMove: false, // スワイプ無効
    loop: false,
    disabledClass: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    // autoplay: {
      //   delay: 3000, // 途切れなくループ
      // },
      breakpoints: {
        // スライドの表示枚数：600px以上の場合
        850: {
          slidesPerView: 1.1,
        },
      },
  });

  const swiper5 = new Swiper ('.swiper5', {
    slidesPerView:1,//画像を何枚表示するか
    loop: false,
    speed: 300,
    allowTouchMove: false, // スワイプ無効
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
      breakpoints: {
        // スライドの表示枚数：600px以上の場合
        850: {
          // loop: true,//最後の画像までいったらループする
          slidesPerView: 1.1,
        },
      },
  });

  const slideBtn = document.querySelectorAll(".tab__list-01")
  slideBtn.forEach((openBtn) => {
    openBtn.addEventListener("click", () => {
      const index = openBtn.dataset.slideIndex;
      swiper4.slideTo(index - 1);
    });
  });

  const slideBtn02 = document.querySelectorAll(".tab__list-02")
  slideBtn02.forEach((openBtn) => {
    openBtn.addEventListener("click", () => {
      const index = openBtn.dataset.slideIndex;
      swiper5.slideTo(index - 1);

    });
  });


  // タブメニュー
  // 最初のコンテンツは表示
  $(".js-content:first-of-type").css("display", "block");
  // タブをクリックすると
  $(".js-tab-01").on("click", function () {
    // 現在選択されているタブからcurrentを外す
    $(".current01").removeClass("current01");
    // クリックされたタブにcurrentクラスを付与
    $(this).addClass("current01");
    // クリックされた要素が何番目か取得（クリックしたタブのインデックス番号を取得）
    const index = $(this).index();
    // コンテンツを非表示にして、クリックしたタブのインデックス番号と同じコンテンツを表示
    $(".js-content").hide().eq(index).fadeIn(300);
  });

  // タブメニュー
  // 最初のコンテンツは表示
  $(".js-content-marketing:first-of-type").css("display", "block");
  // タブをクリックすると
  $(".js-tab-02").on("click", function () {
    // 現在選択されているタブからcurrentを外す
    $(".js-tab-02").removeClass("current02");
    // クリックされたタブにcurrentクラスを付与
    $(this).addClass("current02");
    // クリックされた要素が何番目か取得（クリックしたタブのインデックス番号を取得）
    const index = $(this).index();
    // コンテンツを非表示にして、クリックしたタブのインデックス番号と同じコンテンツを表示
    $(".js-content-marketing").hide().eq(index).fadeIn(300);
  });
});

const height = $(".js-main-lower").height();
$(".top-article-lower").css("margin-top", height - 115);

$(function () {
  $(".text-animation").each(function () {
    // 1文字ずつ<span>で囲む
    $(this)
      .children()
      .addBack()
      .contents()
      .each(function () {
        if (this.nodeType == 3) {
          $(this).replaceWith(
            $(this).text().replace(/(\S)/g, "<span>$1</span>")
          );
        }
      });
    // スクロールして要素が画面内に入ったら文字を表示
    $(this).on("inview", function () {
      // 1文字ずつ順番に表示(不透明にする)
      $(this).css({ opacity: 1 });
      $(this).css({ width: "100%" });
      $(this).css({ height: "100%" });
      // for (var i = 0; i <= $(this).children("span").length; i++) {
      //   $(this)
      //     .children("span")
      //     .eq(i)
      //     .delay(50 * i) // 文字間の時間
      //     .animate({ opacity: 1 }, 500); // 全部表示されるまでの時間
      // }
    });
  });
});

// articleセクション スワイパー
window.addEventListener('DOMContentLoaded', () => {
  const breakPoint = 850;
  let swiper;
  if ( window.innerWidth >= breakPoint ) {
    var swiper3 = new Swiper ('.swiper3', {
      slidesPerView: 4,//画像を何枚表示するか
      loop: true,//最後の画像までいったらループする
      loopAdditionalSlides: 3,
      watchSlidesProgress: true,
      // breakpoints: {
      //   // 850px以上の場合
      //   850: {
      //     slidesPerView: 3,
      //   },
      //   // 1300px以上の場合
      //   1250: {
      //     slidesPerView: 6.2,
      //   },
      // },
      spaceBetween: 40,//何ピクセル画像の間隔をあけるか
      // centeredSlides : true,//見切らせたい場合メイン画像をセンターにもってくるか
      //自動再生する場合
      // autoplay: {
      // delay: 3000, //3秒後に次の画像に代わる
      // },
    });
  } else {
    swiper = undefined;
  }
}, false);

  // var swiper3 = new Swiper ('.swiper3', {
  //   slidesPerView: 'auto',//画像を何枚表示するか
  //   loop: true,//最後の画像までいったらループする
  //   loopAdditionalSlides: 1,
  //   watchSlidesProgress: true,
  //   breakpoints: {
  //     // 768px以上の場合
  //     768: {
  //       slidesPerView: 2,
  //     },
  //     // 980px以上の場合
  //     980: {
  //       slidesPerView: 3,
  //     },
  //     // 1200px以上の場合
  //     1200: {
  //       slidesPerView: 4,
  //     },
  //   },
  //   spaceBetween: 50,//何ピクセル画像の間隔をあけるか
  //   // centeredSlides : true,//見切らせたい場合メイン画像をセンターにもってくるか
  //   //自動再生する場合
  //   autoplay: {
  //   delay: 3000, //3秒後に次の画像に代わる
  //   },
  // });


  if(document.URL.match(/service/)){
    const swiperPrev01 = document.querySelector(".swiper4-prev")
    const swipernext01 = document.querySelector(".swiper4-next")
    const swiperPrev02 = document.querySelector(".swiper5-prev")
    const swipernext02 = document.querySelector(".swiper5-next")
  
  
    swiperPrev01.addEventListener("click", () => {
      $(".current01").prev().addClass("current01");
      $(".current01").next().removeClass("current01");
    })
  
    swipernext01.addEventListener("click", () => {
      $(".current01").next().addClass("current01");
      $(".current01").prev().removeClass("current01");
    })
  
    swiperPrev02.addEventListener("click", () => {
      $(".current02").prev().addClass("current02");
      $(".current02").next().removeClass("current02");
    })
  
    swipernext02.addEventListener("click", () => {
      $(".current02").next().addClass("current02");
      $(".current02").prev().removeClass("current02");
    })
  }


  // const keyName = 'loadingviewed';
  // const keyValue = true;
  
  // if (!sessionStorage.getItem(keyName)) {
  //     sessionStorage.setItem(keyName, keyValue);
  // // 初回閲覧時
  // window.onload = function() {
  //   var popup = document.getElementById('firstTimeModal');
  //   if(!popup) return;
  //   popup.classList.add('is-show'); // モーダルにis-showのclassを付与
  // }
  // const loadinglogo = document.getElementById("loadingLogo"); // 
  // window.addEventListener('DOMContentLoaded', () => { //ロード完了後イベント開始
  //   loadinglogo.className = "show"; 
  //   setTimeout(function(){ loadinglogo.className = loadinglogo.className.replace("show", ""); }, 7500); // 3.5秒後非表示
  // });
  
  // } else {
  // // 2回目以降の処理内容
  
  // }

  
  var now = new Date()
  $.cookie('firstView', 'false');


  window.onload = function(){
    //マウスストーカー用のdivを取得
    const stalker = document.getElementById('stalker');
  
    //aタグにホバー中かどうかの判別フラグ
    let hovFlag = false;
  
    //マウスに追従させる処理 （リンクに吸い付いてる時は除外する）
    document.addEventListener('mousemove', function (e) {
        if (!hovFlag) {
        stalker.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';
        }
    });
  }

window.addEventListener('scroll', function(){
  if( 20 < window.scrollY ) {
    document.getElementById('sp-scroll').classList.add('scroll-color');
  } else {
    document.getElementById('sp-scroll').classList.remove('scroll-color');
  }
});

window.addEventListener('scroll', function(){
  if( 20 < window.scrollY ) {
    document.getElementById('logo-color').classList.add('headerLogoScroll');
  } else {
    document.getElementById('logo-color').classList.remove('headerLogoScroll');
  }
});

window.addEventListener('scroll', function(){
  if( 20 < window.scrollY ) {
    document.getElementById('logo-block').classList.add('headerLogoScroll');
  } else {
    document.getElementById('logo-block').classList.remove('headerLogoScroll');
  }
});

jQuery(function() {

var footer = $('.main__video').innerHeight(); // footerの高さを取得

window.onscroll = function () {
  var point = window.pageYOffset; // 現在のスクロール地点 
  var docHeight = $(document).height(); // ドキュメントの高さ
  var dispHeight = $(window).height(); // 表示領域の高さ

  if(point > docHeight-dispHeight-footer){ // スクロール地点>ドキュメントの高さ-表示領域-footerの高さ
    $('.page-top').addClass('is-hidden'); //footerより下にスクロールしたらis-hiddenを追加
  }else{
    $('.page-top').removeClass('is-hidden'); //footerより上にスクロールしたらis-hiddenを削除
  }
};
});


const body = document.getElementsByTagName('body')[0];
const btn = document.querySelectorAll('#btn');
let scrollTop = 0;

// btn.forEach(el => {
//   el.addEventListener('click', () => {
//     if (body.classList.contains('no_scroll') ) {
//       // スクロール開放
//       body.style.top = '';
//       body.classList.remove('no_scroll');
//       window.scrollTo(0, scrollTop);
//     } else {
//       // スクロール禁止
//       scrollTop = window.scrollY;
//       body.style.top = (scrollTop * -1) + 'px';
//       body.classList.add('no_scroll');
//     }
//   });
// })


//memberページ モーダル
$('.member-list__list-item').on('click', function() {
  let modalTarget = $(this).data('modal-link');
  let modal = document.querySelector('.' + modalTarget);
  $(modal).toggleClass('is-show');
  // $('body').css('overflow-y','hidden');
  
  scrollTop = window.scrollY;
  body.style.top = (scrollTop * -1) + 'px';
  body.classList.add('no_scroll');

});

//   modalを閉じるときの動作
$('.modal__bg').on('click', function() {
  $(this).parents('.modal').toggleClass('is-show');
  body.style.top = '';
  body.classList.remove('no_scroll');
  window.scrollTo(0, scrollTop);
});
$('.modal__close-button').on('click', function() {
  $(this).parents('.modal').removeClass('is-show');
  $('body').removeClass('no_scroll');
  window.scrollTo(0, scrollTop);
  body.style.top = '';
  body.classList.remove('no_scroll');
  window.scrollTo(0, scrollTop);
});
